import {
    reactive, ref
} from "vue";


export function formStateObj() {

    const ruleForm = reactive({
        username: "",
        password: "",
        auth:1, //1 塔吊
        validateCode: "",
    });
    
    const isPassword  = ref(true)
    const rulesObj = {
        username: [{
                required: true,
                message: "请输入用户名",
                trigger: "blur"
            },
            {
                min: 3,
                max: 12,
                message: "长度在 3 到 12个字符",
                trigger: "blur"
            },
        ],
        password: [{
                required: true,
                message: "请输入密码",
                trigger: "blur"
            },
            {
                min: 3,
                max: 12,
                message: "密码长度在 3 到 12个字符",
                trigger: "blur",
            },
        ],
    };

    return {
        ruleForm,
        rulesObj,
        isPassword
    };
}